<script lang="ts" setup></script>
<template>
  <div class="social">
    <h5 class="sotial-title label">{{ $t('footer.follow.title') }}</h5>
    <ul class="social-icons cul">
      <li>
        <Button secondary class="social-icon social-icon-twitter" href="https://twitter.com/CareumCH" target="_blank">
          <Icon name="twitter" />
        </Button>
      </li>
      <li>
        <Button
          secondary
          class="social-icon social-icon-youtube"
          href="https://www.youtube.com/channel/UCrgOHl5ElXoZAErqQ3WnPdA"
          target="_blank"
        >
          <Icon name="youtube" />
        </Button>
      </li>

      <li>
        <Button
          secondary
          class="social-icon social-icon-linkedin"
          href="https://www.linkedin.com/company/careum"
          target="_blank"
        >
          <Icon name="linkedin" />
        </Button>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.social-title {
  margin-bottom: 24px;
}
.social-icons {
  display: flex;
  gap: 4px;
}
.social-icon {
  @include fluid-ts-ds(--pv, 14px, 19px);
  @include fluid-ts-ds(--ph, 14px, 19px);
  @include fluid(--size-icon, 20px, 24px);
  svg {
    width: var(--size-icon);
    height: var(--size-icon);
  }
}
</style>
